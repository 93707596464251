import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSnackbar, VariantType } from 'notistack';
import React from 'react';
import { useSelector } from 'react-redux';
const { setHours, setMinutes, addMinutes, isBefore } = 'date-fns';
import { ICampaing } from '../../../Auth/interfaces/campaing.interface';
import { IReducers } from '../../../Auth/store/store';
import { FowardButtonProps } from '../../../utils/interfaces';
import {
  IMatrixVariables,
  IAIMatrixVariables,
  WMatrixVariables,
} from '../../Stepper/VariablesStep/interfaces';
import {
  StyledArrowFoward,
  StyledFowardButton,
  TypographyButtonsFowardBack,
} from './styles';

interface IContextForm {
  schedule_date: string;
  template: string;
  filename: string;
  contact_variable: string;
  title: string;
  sender: number;
  campaign_type: string;
  matrix_variables:
    | IMatrixVariables[]
    | IAIMatrixVariables[]
    | WMatrixVariables[];
  headers: string[];
  start_date: string;
  end_date: string;
  is_schedule_enabled: boolean;
}

const FowardButton: React.FC<FowardButtonProps> = ({
  isLastStep,
  handleClickOpen,
  setStep,
}: FowardButtonProps): JSX.Element => {
  const { t } = useTranslation();

  const {
    values: {
      schedule_date,
      template,
      filename,
      contact_variable,
      title,
      sender,
      campaign_type,
      start_date,
      end_date,
      start_hour,
      matrix_variables,
      cost_center_id,
      name_variable_index,
      id_ai_user_layout,
      is_schedule_enabled,
      rcs_content_type,
      rcs_content_text,
      rcs_content_file_type,
      rcs_content_file_url,
      rcs_rich_card_file,
      rcs_content_title,
      suggestions,
      company_cnpj,
			template_ids,
			business_id,
			has_file_error
    },
  } = useFormikContext<IContextForm>();
  const { templateVariables, wTemplateVariables }: ICampaing = useSelector(
    (state: IReducers) => state.campaing
  );

  const { enqueueSnackbar } = useSnackbar();

  const handleAlertMessage = (
    message: string,
    variant: VariantType | undefined = 'warning',
    duration = 3000
  ): void => {
    enqueueSnackbar(message, {
      preventDuplicate: true,
      variant: variant,
      autoHideDuration: duration,
    });
  };

  const handleCheckout = (e: any): void => {
    if (campaign_type === 'SMS') {
      if (start_date || end_date || is_schedule_enabled) {
        if (!schedule_date) {
          handleAlertMessage(t('create-campaign.validations.schedule-date'));
        } else {
          e.preventDefault();
          handleClickOpen();
        }
      } else {
        e.preventDefault();
        handleClickOpen();
      }
    } else if (campaign_type === 'WHATSAPP') {
      e.preventDefault();
      handleClickOpen();
    }
  };

  const handleCheckoutTemplate = (): boolean => {
    if (campaign_type === 'RCS' || campaign_type === "WHATSAPP") {
      return true;
    } else if (template || id_ai_user_layout) {
      return true;
    } else {
      handleAlertMessage(t('create-campaign.validations.template'));
      return false;
    }
  };

  const handleCheckoutVariables = (): boolean => {
    const vMatrixVariables = matrix_variables
      .filter(variable => {
        if ('template_variable' in variable) {
          return true;
        }
        return false;
      })
      .map(item => item.template_variable);

    const nTemplateVariables = templateVariables
      .filter(variable => {
        if (variable.includes('ia___') || variable.includes('w___')) {
          return false;
        }
        return true;
      })
      .map(variable => {
        return variable.replace(/}|{/g, '');
      });

    const arraysEqual = vMatrixVariables.length === nTemplateVariables.length;
    //   vMatrixVariables.every(function (value, index) {
    //     return value === nTemplateVariables[index];
    //   });

    if (!arraysEqual) {
      handleAlertMessage(
        t('create-campaign.validations.variables'),
        'info',
        3000
      );
      return false;
    }
    return true;
  };

  const handleCheckoutFileAndContacts = (): boolean => {
    // if (campaign_type === 'WHATSAPP' && name_variable_index === undefined) {
    //   handleAlertMessage(
    //     'É preciso selecionar uma variável de nome para prosseguir.'
    //   );
    //   return false;
    // }

		if (has_file_error) {
			handleAlertMessage("Você precisa corrigir o arquivo antes de prosseguir.");
			return false;
		}

    const contactVariable = matrix_variables.find(
      elem => elem.w_template_variable === 'w___contato'
    );
    if (filename && contactVariable) {
      // if (campaign_type === 'WHATSAPP') {
      //   const nameVariable = matrix_variables.find(
      //     elem => elem.w_template_variable === 'w___nome'
      //   );
      //   if (!nameVariable) {
      //     handleAlertMessage(t('create-campaign.validations.name-variable'));
      //     return false;
      //   }
      //   return true;
      // }
      return true;
    } else {
			if (filename && !contactVariable && campaign_type === "WHATSAPP") {
				return true;
			} else if (filename && !contactVariable && campaign_type === "EMAIL") {
				return true;
			} else {
				handleAlertMessage(
					t('create-campaign.validations.file-and-contact-variable')
				);
				return false;
			}
    }
  };

  const handleCheckoutTitleAndSender = (): void => {
    if (
      campaign_type === 'SMS' ||
      campaign_type === 'WHATSAPP' ||
      campaign_type === 'RCS' ||
      campaign_type === 'EMAIL'
    ) {
      if (title && cost_center_id && campaign_type) {
        setStep(isLastStep + 1);
      } else {
        if (!title) {
          handleAlertMessage(t('create-campaign.validations.title'), 'warning');
        } else if (!cost_center_id) {
          handleAlertMessage(
            t('create-campaign.validations.cost-center'),
            'warning'
          );
        }
      }
    } else {
      if (title && sender && campaign_type) {
        setStep(isLastStep + 1);
      } else {
        handleAlertMessage(t('create-campaign.validations.title'), 'warning');
      }
    }
  };

  function isInvalid(value: any) {
    return value === null || value === undefined || value === '';
  }

  const handleCheckoutRCSContent = (): boolean => {
    if (campaign_type !== 'RCS') return true;

    let error: string;

    if (rcs_content_type === 'TEXT') {
      if (isInvalid(rcs_content_text)) {
        error = t('create-campaign.validations.rcs-content-text');
      }
    }

    if (rcs_content_type === 'RICHCARD') {
      if (
        rcs_content_file_type === 'file_url' &&
        isInvalid(rcs_content_file_url)
      ) {
        error = t('create-campaign.validations.rcs-content-file-url');
      } else if (
        rcs_content_file_type === 'local_file' &&
        isInvalid(rcs_rich_card_file)
      ) {
        error = t('create-campaign.validations.rcs-content-file-url');
      } else if (isInvalid(rcs_content_title)) {
        error = t('create-campaign.validations.rcs-content-title');
      }
    }

    if (error) {
      handleAlertMessage(error);
      return false;
    }

    return true;
  };

  const handleCheckoutRCSSuggestions = (): boolean => {
    if (campaign_type !== 'RCS') return true;

    let error: string;

    if (suggestions.length) {
      for (const suggestion of suggestions) {
        if (isInvalid(suggestion.type)) {
          error = t('create-campaign.validations.rcs-suggestions-type');
        } else if (isInvalid(suggestion.title)) {
          error = t('create-campaign.validations.rcs-suggestions-title');
        } else if (isInvalid(suggestion.value)) {
          error = t('create-campaign.validations.rcs-suggestions-value');
        }

        if (error) {
          break;
        }
      }
    }

    if (error) {
      handleAlertMessage(error);
      return false;
    }

    return true;
  };

  const handleCheckoutWhatsapp = (): boolean => {
    if (campaign_type !== 'WHATSAPP') return true;

    let error: string;

    if (!company_cnpj || !business_id) {
      error = t('create-campaign.validations.whatsapp-company');
    } else if (!template_ids?.length) {
			error = "Selecione um ou mais templates para prosseguir.";
		}

    if (error) {
      handleAlertMessage(error);
      return false;
    }

    return true;
  };

  const handleCheckoutEmail = (): boolean => {
    if (campaign_type !== 'EMAIL') return true;

    let error: string;

    if (!sender) {
      error = t('create-campaign.validations.sender');
    } else if (!template) {
			error = t('create-campaign.validations.template');
		}

    if (error) {
      handleAlertMessage(error);
      return false;
    }

    return true;
  };

  const checkThreeSteps = e => {
    const valid =
      handleCheckoutFileAndContacts() &&
      handleCheckoutTemplate() &&
      handleCheckoutVariables() &&
      handleCheckoutRCSContent() &&
      handleCheckoutRCSSuggestions() &&
      handleCheckoutWhatsapp() &&
      handleCheckoutEmail();

    if (!valid) return;

    if (campaign_type === 'SMS') {
      setStep(isLastStep + 1);
    } else if (campaign_type === 'WHATSAPP') {
      handleClickOpen();
    } else if (campaign_type === 'RCS') {
      handleClickOpen();
    } else if (campaign_type === 'EMAIL') {
      handleClickOpen();
    }
  };

  return (
    <>
      <StyledFowardButton
        variant="contained"
        type={'button'}
        onClick={e => {
          campaign_type === 'SMS' && isLastStep === 2 && handleCheckout(e);
          isLastStep === 1 && checkThreeSteps(e);
          isLastStep === 0 && handleCheckoutTitleAndSender();
        }}
      >
        {(campaign_type === 'SMS' && isLastStep === 2) ||
        (campaign_type === 'WHATSAPP' && isLastStep === 1) ||
        (campaign_type === 'EMAIL' && isLastStep === 1) ||
        (campaign_type === 'RCS' && isLastStep === 1) ? (
          <TypographyButtonsFowardBack variant="body1">
            {t('create-campaign.buttons.finish')}
          </TypographyButtonsFowardBack>
        ) : (
          <TypographyButtonsFowardBack variant="body1">
            {t('create-campaign.buttons.forward')}
          </TypographyButtonsFowardBack>
        )}
        {isLastStep === 3 ? null : <StyledArrowFoward />}
      </StyledFowardButton>
    </>
  );
};

export default FowardButton;
