import { AxiosResponse } from 'axios';
import { BASE_WHATSAPP_URL } from '../../api';
import { request } from '../BASE_REQUEST';

const getWhatsappTemplates = async (params?: any): Promise<
  AxiosResponse<any> | undefined
> => {
  try {
    const response = await request({
      url: `${BASE_WHATSAPP_URL}/whatsapp/hsm/templates/`,
      method: 'GET',
			params,
    });
    return response;
  } catch (error) {
    Promise.reject(error);
  }
};

export default getWhatsappTemplates;
