import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import Campaign from './pages/Campaign';
import { Dashboard } from './pages/Home';
import { Settings } from './pages/Settings';
import { IReducers } from './Auth/store/store';
import Users from './pages/Users';
import MainUsers from './pages/MainUsers/MainUsers';
import { SendersList } from './pages/SendersList';
import { CampaingOpen } from './pages/CampaingOpen';
import { TemplateList } from './pages/TemplateList';
import { RouteList } from './pages/RouteList';
import { IAuth } from './Auth/interfaces/auth.interface';
import { EditTemplate } from './components/EditTemplate';
import CampaignDetails from './pages/CampaignDetails/CampaignDetails';
import { PlanControlList } from './pages/PlansControlList';
import { Transactions } from './pages/Transactions';
import { ContainmentList } from './pages/ContainmentList';
import Report from './pages/Report/Report';
import AILayouts from './pages/AILayouts/AILayouts';
import AILayoutsDetails from './pages/AILayoutsDetails/AILayoutsDetails';
import AITabs from './pages/AITabs/AITabs';
import DataRequestList from './pages/DataRequestList/DataRequestList';
import CostCenter from './pages/CostCenter/CostCenter';
import Phones from './pages/Phones/Phones';
import Admin from './pages/Admin/Admin'

const Rotas: React.FC = () => {
	const { is_staff, is_main, hasAI, hasData }: IAuth = useSelector(
		(state: IReducers) => state.auth
	);

	return (
		<Switch>
			<Route exact path="/">
				<Redirect to="/home" />
			</Route>
			<Route exact path="/home" component={Dashboard} />
			<Route exact path="/campaing" component={Campaign} />
			<Route exact path="/campaign-list" component={Report} />
			{/* <Route exact path="/report" component={Report} /> */}
			<Route exact path="/campaign-open" component={CampaingOpen} />
			<Route exact path="/settings" component={Settings} />
			<Route exact path="/templates-list" component={TemplateList} />
			<Route exact path="/senders-list" component={SendersList} />
			<Route exact path="/edit-template" component={EditTemplate} />
			<Route exact path="/containment-list" component={ContainmentList} />
			<Route exact path="/phones" component={Phones} />
			<Route exact path="/cost-center" component={CostCenter} />
			<Route exact path="/email-campaign/:id/" component={CampaignDetails} />
			<Route
				exact
				path="/sms-campaign-details/:id/"
				component={CampaignDetails}
			/>
			{hasData && <Route exact path="/data-requests" component={DataRequestList} />}
			{is_main && <Route exact path="/main-users" component={MainUsers} />}
			{hasAI && <Route exact path="/ai-layouts" component={AILayouts} />}
			{hasAI && <Route exact path="/ai-layouts-details/:aiUserLayoutId" component={AILayoutsDetails} />}
			{hasAI && <Route exact path="/ai-tabs" component={AITabs} />}
			{/* {is_staff && <Route exact path="/route-list" component={RouteList} />} */}
			{/* {<Route exact path="/users" component={Users} />} */}
			{/* {is_staff && <Route exact path="/plans-control" component={PlanControlList} />} */}
			{is_staff && <Route exact path="/transactions/:id" component={Transactions} />}
			{is_staff && <Route exact path="/admin" component={Admin} />}
		</Switch>
	);
};

export default Rotas;
