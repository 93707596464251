import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import getReport from '../../utils/api/GET/getReport';
import { triggerCampaign } from '../../utils/api/POST/triggerCampaign.js';

import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog';

import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';

import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import ListIcon from '@mui/icons-material/List';

import { pauseCampaign } from '../../utils/api/PATCH/pauseCampaign.js';
import { resumeCampaign } from '../../utils/api/PATCH/resumeCampaign.js';

import Container from '../../components/Container';
import MiddleContainer from '../../components/Report/MiddleContainer';
import NumberMask from '../../components/NumberMask/NumberMask';

import { NoWrapSpan } from './styles';

import { getCostCenter } from '../../utils/api/GET';
import { cancelCampaign } from '../../utils/api/PATCH/cancelCampaign.js';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import useQuery from '../../hooks/useQuery';

import { LoadingContainer } from './styles';

import { BASE_RCS_URL, BASE_URL, BASE_WHATSAPP_URL, BASE_EMAIL_URL } from '../../utils/api';

const PAGE_SIZE = 40;

export default function Report() {
  const { t } = useTranslation();

  Number.prototype.format = function () {
    return this.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };

  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [fields, setFields] = useState({
    tags: [],
    status: '',
    start_date: new Date(),
    end_date: new Date(),
    cost_center_id: null,
  });
  const [headers, setHeaders] = useState({
    analyzed: 0,
    invalid: 0,
    triggered: 0,
    delivered: 0,
    not_delivered: 0,
    cost: 0,
    total: 0,
    blacklist: 0,
  });
  const query = useQuery();
  const [report, setReport] = useState([]);
  const [whatsappReport, setWhatsappReport] = useState([]);
  const [rcsReport, setRcsReport] = useState([]);
	const [emailReport, setEmailReport] = useState([]);

  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [nextPage, setNextPage] = useState(0);
  const [previousPage, setPreviousPage] = useState(0);
  const [triggerId, setTriggerId] = useState(null);
  const history = useHistory();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [targetURL, setTargetURL] = useState(null);
  const [costCenters, setCostCenter] = useState([]);

  const { is_main, hasWhatsapp, hasRCS, hasEmail } = useSelector(state => state.auth);

  const [campaignType, setCampaignType] = useState(() => {
    const defaultType = hasWhatsapp ? 'WHATSAPP' : 'SMS';

    const type = query.get('type');

		if (['SMS', 'WHATSAPP', 'RCS', 'EMAIL'].includes(type)) {
      return type;
    } else {
      return defaultType;
    }
  });

  const handleCampaignTypeChange = event => {
    setCampaignType(event.target.value);
		history.push({ search: `?type=${event.target.value}` });
  };

  const handleChange = event => {
    setFields({ ...fields, [event.target.name]: event.target.value });
  };

  const handstartDateChange = date => {
    setFields({ ...fields, start_date: date });
  };

  const handendDateChange = date => {
    setFields({ ...fields, end_date: date });
  };

  const handlePageChange = (newPage: number) => {
    // We have the cursor, we can allow the page transition.
    setCurrentPage(newPage);
    if (newPage - currentPage > 0) {
      setTargetURL(nextPage);
      // alert(nextPage)
    } else {
      setTargetURL(previousPage);
      // alert(previousPage)
    }
  };

  const handleSearchWithNoLoading = async () => {
    if (
      !fields.status &&
      !fields.tags.length &&
      !fields.start_date &&
      !fields.end_date
    ) {
      enqueueSnackbar(t('campaigns.messages.search-with-no-filters'), {
        variant: 'error',
        autoHideDuration: 5000,
      });
    } else {
      try {
        let params = {};
        if (fields.status) {
          params['status'] = fields.status;
        }

        if (fields.tags) {
          params['tags'] = fields.tags.join(',');
        }

        if (fields.start_date) {
          params['start_date'] = moment(fields.start_date).format('DD/MM/YYYY');
        }

        if (fields.end_date) {
          params['end_date'] = moment(fields.end_date).format('DD/MM/YYYY');
        }

        const result = await getReport(targetURL, params);
        if (result.status != 200) {
          enqueueSnackbar(t('campaigns.messages.report-error'), {
            variant: 'error',
            autoHideDuration: 5000,
          });
        } else {
          setPageCount(result.data.count);
          setNextPage(result.data.next);
          setPreviousPage(result.data.previous);
          if (campaignType === 'SMS') {
            setReport(result.data.results);
          } else if (campaignType === 'WHATSAPP') {
            setWhatsappReport(result.data.results);
          } else if (campaignType === 'RCS') {
            setRcsReport(result.data.results);
          } else if (campaignType === 'EMAIL') {
						setEmailReport(result.data.results);
					}
        }
      } catch (error) {
        enqueueSnackbar(t('campaigns.messages.report-error'), {
          variant: 'error',
          autoHideDuration: 5000,
        });
      }
    }
  };

  const handleSearch = async () => {
    if (
      !fields.status &&
      !fields.tags.length &&
      !fields.start_date &&
      !fields.end_date
    ) {
      enqueueSnackbar(t('campaigns.messages.search-with-no-filters'), {
        variant: 'error',
        autoHideDuration: 5000,
      });
    } else {
      try {
        setLoading(true);

        const result = await getReport(targetURL);
        if (result.status != 200) {
          enqueueSnackbar(t('campaigns.messages.report-error'), {
            variant: 'error',
            autoHideDuration: 5000,
          });
        } else {
          setPageCount(result.data.count);
          setNextPage(result.data.next);
          setPreviousPage(result.data.previous);
          if (campaignType === 'SMS') {
            setReport(result.data.results);
          } else if (campaignType === 'WHATSAPP') {
            setWhatsappReport(result.data.results);
          } else if (campaignType === 'RCS') {
            setRcsReport(result.data.results);
          } else if (campaignType === 'EMAIL') {
						setEmailReport(result.data.results);
					}
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);

        enqueueSnackbar(t('campaigns.messages.report-error'), {
          variant: 'error',
          autoHideDuration: 5000,
        });
      }
    }
  };

  const handleCancelCampaign = async () => {
    try {
      const result = await cancelCampaign(triggerId);
      if (result.status != 200) {
        enqueueSnackbar(t('campaigns.messages.cancel-campaign-error'), {
          variant: 'error',
          autoHideDuration: 5000,
        });
      } else {
        await handleSearch();
      }
    } catch (error) {
      enqueueSnackbar(t('campaigns.messages.cancel-campaign-error'), {
        variant: 'error',
        autoHideDuration: 5000,
      });
    }
  };

  const handleGetCenter = async () => {
    try {
      const result = await getCostCenter();
      if (result.status != 200) {
        enqueueSnackbar(t('campaigns.messages.get-cost-center-error'), {
          variant: 'error',
          autoHideDuration: 5000,
        });
      }
      let data = result.data;
      setCostCenter(data);
    } catch (error) {
      enqueueSnackbar(t('campaigns.messages.get-cost-center-error'), {
        variant: 'error',
        autoHideDuration: 5000,
      });
    }
  };

  useEffect(() => {
    setCurrentPage(0);
  }, [campaignType]);

	useEffect(() => {
		if (query.get('type') !== campaignType) {
			history.push({ search: `?type=${campaignType}` });
    }
  }, [history, campaignType, query]);

  useEffect(() => {
    if (targetURL) {
      handleSearch();
      const intervalId = setInterval(() => {
        handleSearchWithNoLoading();
      }, 30000);

      return () => clearInterval(intervalId);
    }
  }, [targetURL]);

  useEffect(() => {
    let params = {};
    if (fields.status) {
      params['status'] = fields.status;
    }

    if (fields.start_date) {
      params['start_date'] = moment(fields.start_date).format('DD/MM/YYYY');
    }

    if (fields.end_date) {
      params['end_date'] = moment(fields.end_date).format('DD/MM/YYYY');
    }

    if (fields.cost_center_id) {
      params['cost_center_id'] = fields.cost_center_id;
    }

    setCurrentPage(0);

    const ret = [];
    for (let d in params)
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(params[d]));
    const res = ret.join('&');
    if (campaignType === 'SMS') {
      setTargetURL(`${BASE_URL}/sms/report/?${res}`);
    } else if (campaignType === 'WHATSAPP') {
      setTargetURL(`${BASE_WHATSAPP_URL}/whatsapp/campaigns/?${res}`);
    } else if (campaignType === 'RCS') {
      setTargetURL(`${BASE_RCS_URL}/rcs/campaigns/?${res}`);
    } else if (campaignType === 'EMAIL') {
			setTargetURL(`${BASE_EMAIL_URL}/email/campaigns/?${res}`);
		}
  }, [fields, campaignType]);

  useEffect(() => {
    handleGetCenter();
  }, []);

  const statusTranslate = {
    ACTIVE: t('campaigns.status.active'),
    MISSING_FILE: t('campaigns.status.analyzing'),
    ERROR: t('campaigns.status.error'),
    PAUSED: t('campaigns.status.paused'),
    SPLITTING: t('campaigns.status.splitting'),
    ANALYZING: t('campaigns.status.analyzing'),
    FINISHED: t('campaigns.status.finished'),
    RUNNING: t('campaigns.status.running'),
    CANCELLED: t('campaigns.status.cancelled'),
    WAITING_VALIDATION: t('campaigns.status.waiting-validation'),
    SCHEDULED: t('campaigns.status.scheduled'),
  };

  const clearFilters = () => {
    const newDate = new Date();

    if (
      fields.status != '' ||
      fields.tags.length > 0 ||
      fields.start_date.toLocaleDateString('pt-BR') !==
        newDate.toLocaleDateString('pt-BR') ||
      fields.end_date.toLocaleDateString('pt-BR') !==
        newDate.toLocaleDateString('pt-BR') ||
      fields.cost_center_id
    ) {
      setFields({
        tags: [],
        status: '',
        start_date: new Date(),
        end_date: new Date(),
        cost_center_id: '',
      });
      setPageCount(0);
      setNextPage(null);
      setPreviousPage(null);
      setReport([]);
    }
  };

  const handleOpenCancelDialog = triggerId => {
    setOpenCancelDialog(true);
    setTriggerId(triggerId);
  };

  const handleCloseCancelDialog = () => {
    setOpenCancelDialog(false);
  };

  const handleOkDialog = () => {
    setOpenConfirmDialog(false);
    handleTriggerCampaign();
  };

  const handleOkCancelDialog = () => {
    setOpenCancelDialog(false);
    handleCancelCampaign();
    // handleTriggerCampaign();
  };

  const handleCancelDialog = () => {
    setOpenCancelDialog(false);
  };

  const handleCloseDialog = () => {
    setOpenConfirmDialog(false);
  };

  const whatsappColumns: GridColDef[] = [
    {
      field: 'title',
      headerName: t('campaigns.table.whatsapp.header.campaign'),
      editable: false,
      flex: 0.7,
      renderCell: params => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Tooltip title={params.row.title}>
            <NoWrapSpan>{params.row.title}</NoWrapSpan>
          </Tooltip>
          <NoWrapSpan>{params.row.created_at}</NoWrapSpan>
        </div>
      ),
    },
    {
      field: 'status',
      headerName: t('campaigns.table.whatsapp.header.status'),
      editable: false,
      flex: 0.3,
      renderCell: params => (
        <Tooltip title={statusTranslate[params.row.status]}>
          <NoWrapSpan>{statusTranslate[params.row.status]}</NoWrapSpan>
        </Tooltip>
      ),
    },
    {
      field: 'total',
      headerName: t('campaigns.table.whatsapp.header.total'),
      editable: false,
      flex: 0.2,
      renderCell: params => (
        <Tooltip
          title={
            <NumberMask
              label={t('campaigns.table.whatsapp.tooltip.total')}
              number={params.row.count_message_total}
            />
          }
        >
          <NoWrapSpan>
            <NumberMask number={params.row.count_message_total} />
          </NoWrapSpan>
        </Tooltip>
      ),
    },
    {
      field: 'invalid',
      headerName: t('campaigns.table.whatsapp.header.invalid'),
      editable: false,
      flex: 0.25,
      renderCell: params => (
        <Tooltip
          title={
            <NumberMask
              label={t('campaigns.table.whatsapp.tooltip.invalid')}
              number={params.row.count_message_invalid}
            />
          }
        >
          <NoWrapSpan>
            <NumberMask number={params.row.count_message_invalid} />
          </NoWrapSpan>
        </Tooltip>
      ),
    },
    {
      field: 'processed',
      headerName: t('campaigns.table.whatsapp.header.processed'),
      editable: false,
      flex: 0.25,
      renderCell: params => (
        <Tooltip
          title={
            <NumberMask
              label={t('campaigns.table.whatsapp.tooltip.processed')}
              number={params.row.count_message_processed}
            />
          }
        >
          <NoWrapSpan>
            <NumberMask number={params.row.count_message_processed} />
          </NoWrapSpan>
        </Tooltip>
      ),
    },
    {
      field: 'does_not_exists',
      headerName: t('campaigns.table.whatsapp.header.does-not-exists'),
      editable: false,
      flex: 0.25,
      renderCell: params => (
        <Tooltip
          title={
            <NumberMask
              label={t('campaigns.table.whatsapp.tooltip.does-not-exists')}
              number={params.row.count_message_does_not_exists}
            />
          }
        >
          <NoWrapSpan>
            <NumberMask number={params.row.count_message_does_not_exists} />
          </NoWrapSpan>
        </Tooltip>
      ),
    },
    {
      field: 'sent',
      headerName: t('campaigns.table.whatsapp.header.sent'),
      editable: false,
      flex: 0.25,
      renderCell: params => (
        <Tooltip
          title={
            <NumberMask
              label={t('campaigns.table.whatsapp.tooltip.sent')}
              number={params.row.count_message_server}
            />
          }
        >
          <NoWrapSpan>
            <NumberMask number={params.row.count_message_server} />
          </NoWrapSpan>
        </Tooltip>
      ),
    },
    {
      field: 'delivered',
      headerName: t('campaigns.table.whatsapp.header.delivered'),
      editable: false,
      flex: 0.25,
      renderCell: params => (
        <Tooltip
          title={
            <NumberMask
              label={t('campaigns.table.whatsapp.tooltip.delivered')}
              number={params.row.count_message_device}
            />
          }
        >
          <NoWrapSpan>
            <NumberMask number={params.row.count_message_device} />
          </NoWrapSpan>
        </Tooltip>
      ),
    },
    {
      field: 'percentage',
      headerName: t('campaigns.table.whatsapp.header.percentage'),
      flex: 0.083,
      editable: false,
      width: 150,
      renderCell: (params: any) => {
        const n = parseInt(params.row.count_message_server);
        const d =
          parseInt(params.row.count_message_total) -
          parseInt(params.row.count_message_invalid);

        if (d != 0) {
          const result = (n / d) * 100;
          return (
            <Tooltip
              title={
                <NumberMask
                  label={t('campaigns.table.whatsapp.tooltip.percentage')}
                  number={parseInt(result)}
                />
              }
            >
              <NoWrapSpan>
                <NumberMask number={parseInt(result)} />%
              </NoWrapSpan>
            </Tooltip>
          );
        }
        return '0%';
      },
    },
    {
      field: 'actions',
      headerName: t('campaigns.table.whatsapp.header.actions'),
      sortable: false,
      editable: false,
      flex: 0.2,
      align: 'center',
      renderCell: params => (
        <>
          {params.row.status === 'FINISHED' ? (
            <CheckCircleOutlineIcon></CheckCircleOutlineIcon>
          ) : (
            <IconButton
              onClick={() => {
                handleOpenDialog(parseInt(params.id.toString()));
              }}
              disabled={
                params.row.status === 'ACTIVE' && params.row.bucket
                  ? false
                  : true
              }
              style={{
                opacity:
                  params.row.status === 'ACTIVE' && params.row.bucket
                    ? '1'
                    : '0.3',
              }}
            >
              {loading ? (
                <CircularProgress size={20} />
              ) : (
                <Tooltip
                  title={t('campaigns.table.whatsapp.tooltip.actions.trigger')}
                >
                  <PlayCircleIcon color="primary" />
                </Tooltip>
              )}
            </IconButton>
          )}
        </>
      ),
      width: 100,
    },
  ];

  const rcsColumns: GridColDef[] = [
    {
      field: 'title',
      headerName: t('campaigns.table.rcs.header.campaign'),
      editable: false,
      flex: 0.7,
      renderCell: params => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Tooltip title={params.row.title}>
            <NoWrapSpan>{params.row.title}</NoWrapSpan>
          </Tooltip>
          <NoWrapSpan>{params.row.created_at}</NoWrapSpan>
        </div>
      ),
    },
    {
      field: 'status',
      headerName: t('campaigns.table.rcs.header.status'),
      editable: false,
      flex: 0.3,
      renderCell: params => (
        <Tooltip title={statusTranslate[params.row.status]}>
          <NoWrapSpan>{statusTranslate[params.row.status]}</NoWrapSpan>
        </Tooltip>
      ),
    },
    {
      field: 'total',
      headerName: t('campaigns.table.rcs.header.total'),
      editable: false,
      flex: 0.2,
      renderCell: params => (
        <Tooltip
          title={
            <NumberMask
              label={t('campaigns.table.rcs.tooltip.total')}
              number={params.row.count_message_total}
            />
          }
        >
          <NoWrapSpan>
            <NumberMask number={params.row.count_message_total} />
          </NoWrapSpan>
        </Tooltip>
      ),
    },
    {
      field: 'invalid',
      headerName: t('campaigns.table.rcs.header.invalid'),
      editable: false,
      flex: 0.25,
      renderCell: params => (
        <Tooltip
          title={
            <NumberMask
              label={t('campaigns.table.rcs.tooltip.invalid')}
              number={params.row.count_rcs_invalid}
            />
          }
        >
          <NoWrapSpan>
            <NumberMask number={params.row.count_rcs_invalid} />
          </NoWrapSpan>
        </Tooltip>
      ),
    },
    {
      field: 'processed',
      headerName: t('campaigns.table.rcs.header.processed'),
      editable: false,
      flex: 0.25,
      renderCell: params => (
        <Tooltip
          title={
            <NumberMask
              label={t('campaigns.table.rcs.tooltip.processed')}
              number={params.row.count_rcs_triggered}
            />
          }
        >
          <NoWrapSpan>
            <NumberMask number={params.row.count_rcs_triggered} />
          </NoWrapSpan>
        </Tooltip>
      ),
    },
    {
      field: 'does_not_exists',
      headerName: t('campaigns.table.rcs.header.does-not-exists'),
      editable: false,
      flex: 0.25,
      renderCell: params => (
        <Tooltip
          title={
            <NumberMask
              label={t('campaigns.table.rcs.tooltip.does-not-exists')}
              number={params.row.count_rcs_does_not_support}
            />
          }
        >
          <NoWrapSpan>
            <NumberMask number={params.row.count_rcs_does_not_support} />
          </NoWrapSpan>
        </Tooltip>
      ),
    },
    {
      field: 'delivered',
      headerName: t('campaigns.table.rcs.header.delivered'),
      editable: false,
      flex: 0.25,
      renderCell: params => (
        <Tooltip
          title={
            <NumberMask
              label={t('campaigns.table.rcs.tooltip.delivered')}
              number={params.row.count_rcs_delivered}
            />
          }
        >
          <NoWrapSpan>
            <NumberMask number={params.row.count_rcs_delivered} />
          </NoWrapSpan>
        </Tooltip>
      ),
    },
    {
      field: 'percentage',
      headerName: t('campaigns.table.rcs.header.percentage'),
      flex: 0.083,
      editable: false,
      width: 150,
      renderCell: (params: any) => {
        const n = parseInt(params.row.count_rcs_delivered);
        const d = parseInt(params.row.count_rcs_triggered);

        if (d != 0) {
          const result = (n / d) * 100;
          return (
            <Tooltip
              title={
                <NumberMask
                  label={t('campaigns.table.rcs.tooltip.percentage')}
                  number={parseInt(result)}
                />
              }
            >
              <NoWrapSpan>
                <NumberMask number={parseInt(result)} />%
              </NoWrapSpan>
            </Tooltip>
          );
        }

        return '0%';
      },
    },
    {
      field: 'actions',
      headerName: t('campaigns.table.rcs.header.actions'),
      sortable: false,
      editable: false,
      flex: 0.2,
      align: 'center',
      renderCell: params => (
        <>
          {params.row.status === 'FINISHED' ? (
            <CheckCircleOutlineIcon></CheckCircleOutlineIcon>
          ) : (
            <IconButton
              onClick={() => {
                handleOpenDialog(parseInt(params.id.toString()));
              }}
              disabled={
                params.row.status === 'ACTIVE' && params.row.source === 2
                  ? false
                  : true
              }
              style={{
                opacity:
                  params.row.status === 'ACTIVE' && params.row.source === 2
                    ? '1'
                    : '0.3',
              }}
            >
              {loading ? (
                <CircularProgress size={20} />
              ) : (
                <Tooltip
                  title={t('campaigns.table.rcs.tooltip.actions.trigger')}
                >
                  <PlayCircleIcon color="primary" />
                </Tooltip>
              )}
            </IconButton>
          )}
        </>
      ),
      width: 100,
    },
  ];

  const emailColumns: GridColDef[] = [
    {
      field: 'title',
      headerName: t('campaigns.table.email.header.campaign'),
      editable: false,
      flex: 0.7,
      renderCell: params => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Tooltip title={params.row.title}>
            <NoWrapSpan>{params.row.title}</NoWrapSpan>
          </Tooltip>
          <NoWrapSpan>{params.row.created_at}</NoWrapSpan>
        </div>
      ),
    },
    {
      field: 'status',
      headerName: t('campaigns.table.email.header.status'),
      editable: false,
      flex: 0.3,
      renderCell: params => (
        <Tooltip title={statusTranslate[params.row.status]}>
          <NoWrapSpan>{statusTranslate[params.row.status]}</NoWrapSpan>
        </Tooltip>
      ),
    },
    {
      field: 'sent',
      headerName: t('campaigns.table.email.header.sent'),
      editable: false,
      flex: 0.25,
      renderCell: params => (
        <Tooltip
          title={
            <NumberMask
              label={t('campaigns.table.email.tooltip.sent')}
              number={params.row.count_emails_sent}
            />
          }
        >
          <NoWrapSpan>
            <NumberMask number={params.row.count_emails_sent} />
          </NoWrapSpan>
        </Tooltip>
      ),
    },
    {
      field: 'delivered',
      headerName: t('campaigns.table.email.header.delivered'),
      editable: false,
      flex: 0.25,
      renderCell: params => (
        <Tooltip
          title={
            <NumberMask
              label={t('campaigns.table.email.tooltip.delivered')}
              number={params.row.count_emails_delivered}
            />
          }
        >
          <NoWrapSpan>
            <NumberMask number={params.row.count_emails_delivered} />
          </NoWrapSpan>
        </Tooltip>
      ),
    },
    {
      field: 'opened',
      headerName: t('campaigns.table.email.header.opened'),
      editable: false,
      flex: 0.25,
      renderCell: params => (
        <Tooltip
          title={
            <NumberMask
              label={t('campaigns.table.email.tooltip.opened')}
              number={params.row.count_emails_opened}
            />
          }
        >
          <NoWrapSpan>
            <NumberMask number={params.row.count_emails_opened} />
          </NoWrapSpan>
        </Tooltip>
      ),
    },
    {
      field: 'not_delivered',
      headerName: t('campaigns.table.email.header.not_delivered'),
      editable: false,
      flex: 0.25,
      renderCell: params => (
        <Tooltip
          title={
            <NumberMask
              label={t('campaigns.table.email.tooltip.not_delivered')}
              number={params.row.count_emails_not_delivered}
            />
          }
        >
          <NoWrapSpan>
            <NumberMask number={params.row.count_emails_not_delivered} />
          </NoWrapSpan>
        </Tooltip>
      ),
    },
    {
      field: 'percentage',
      headerName: t('campaigns.table.email.header.percentage'),
      flex: 0.083,
      editable: false,
      width: 150,
      renderCell: (params: any) => {
        const n = parseInt(params.row.count_emails_delivered);
        const d = parseInt(params.row.count_emails_sent);

        if (d != 0) {
          const result = (n / d) * 100;
          return (
            <Tooltip
              title={
                <NumberMask
                  label={t('campaigns.table.email.tooltip.percentage')}
                  number={parseInt(result)}
                />
              }
            >
              <NoWrapSpan>
                <NumberMask number={parseInt(result)} />%
              </NoWrapSpan>
            </Tooltip>
          );
        }
        return '0%';
      },
    },
    {
      field: 'actions',
      headerName: t('campaigns.table.email.header.actions'),
      sortable: false,
      editable: false,
      flex: 0.2,
      align: 'center',
      renderCell: params => (
        <>
          {params.row.status === 'FINISHED' ? (
            <CheckCircleOutlineIcon></CheckCircleOutlineIcon>
          ) : (
            <IconButton
              onClick={() => {
                handleOpenDialog(parseInt(params.id.toString()));
              }}
              disabled={
                params.row.status === 'ACTIVE' && params.row.source === 2
                  ? false
                  : true
              }
              style={{
                opacity:
                  params.row.status === 'ACTIVE' && params.row.source === 2
                    ? '1'
                    : '0.3',
              }}
            >
              {loading ? (
                <CircularProgress size={20} />
              ) : (
                <Tooltip
                  title={t('campaigns.table.email.tooltip.actions.trigger')}
                >
                  <PlayCircleIcon color="primary" />
                </Tooltip>
              )}
            </IconButton>
          )}
        </>
      ),
      width: 100,
    },
  ];

  const columns: GridColDef[] = [
    {
      field: 'title',
      headerName: t('campaigns.table.sms.header.campaign'),
      editable: false,
      flex: 0.7,
      renderCell: params => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Tooltip title={params.row.title}>
            <NoWrapSpan>{params.row.title}</NoWrapSpan>
          </Tooltip>
          <NoWrapSpan>{params.row.created_at}</NoWrapSpan>
        </div>
      ),
    },
    {
      field: 'status',
      headerName: t('campaigns.table.sms.header.status'),
      editable: false,
      flex: 0.3,
      renderCell: params => (
        <Tooltip title={statusTranslate[params.row.status]}>
          <NoWrapSpan>{statusTranslate[params.row.status]}</NoWrapSpan>
        </Tooltip>
      ),
    },
    {
      field: 'cost_center',
      headerName: t('campaigns.table.sms.header.cost-center'),
      editable: false,
      flex: 0.3,
      renderCell: params => (
        <Tooltip title={params.row.cost_center}>
          <NoWrapSpan>{params.row.cost_center}</NoWrapSpan>
        </Tooltip>
      ),
    },
    {
      field: 'total',
      headerName: t('campaigns.table.sms.header.total'),
      editable: false,
      flex: 0.25,
      renderCell: params => (
        <Tooltip
          title={
            <NumberMask
              label={t('campaigns.table.sms.tooltip.total')}
              number={params.row.total}
            />
          }
        >
          <NoWrapSpan>
            <NumberMask number={params.row.total} />
          </NoWrapSpan>
        </Tooltip>
      ),
    },
    {
      field: 'analyzed',
      headerName: t('campaigns.table.sms.header.sanitized'),
      editable: false,
      flex: 0.4,
      renderCell: params => (
        <Tooltip
          title={
            <NumberMask
              label={t('campaigns.table.sms.tooltip.sanitized')}
              number={
                parseInt(params.row.invalid) +
                parseInt(params.row.count_not_whitelist)
              }
            />
          }
        >
          <NoWrapSpan>
            <NumberMask number={parseInt(params.row.invalid)} />
          </NoWrapSpan>
        </Tooltip>
      ),
    },
    {
      field: 'triggered',
      headerName: t('campaigns.table.sms.header.triggered'),
      editable: false,
      flex: 0.4,
      renderCell: params => (
        <Tooltip
          title={
            <NumberMask
              label={t('campaigns.table.sms.tooltip.triggered')}
              number={params.row.triggered}
            />
          }
        >
          <NoWrapSpan>
            <NumberMask number={params.row.triggered} />
          </NoWrapSpan>
        </Tooltip>
      ),
    },
    {
      field: 'delivered',
      headerName: t('campaigns.table.sms.header.delivered'),
      editable: false,
      flex: 0.4,
      renderCell: params => (
        <Tooltip
          title={
            <NumberMask
              label={t('campaigns.table.sms.tooltip.delivered')}
              number={parseInt(params.row.triggered - params.row.not_delivered)}
            />
          }
        >
          <NoWrapSpan>
            <NumberMask
              number={parseInt(params.row.triggered - params.row.not_delivered)}
            />
          </NoWrapSpan>
        </Tooltip>
      ),
    },
    {
      field: 'not_delivered',
      headerName: t('campaigns.table.sms.header.not-delivered'),
      editable: false,
      flex: 0.5,
      renderCell: params => (
        <Tooltip
          title={
            <NumberMask
              label={t('campaigns.table.sms.tooltip.not-delivered')}
              number={parseInt(params.row.not_delivered)}
            />
          }
        >
          <NoWrapSpan>
            <NumberMask number={parseInt(params.row.not_delivered)} />
          </NoWrapSpan>
        </Tooltip>
      ),
    },
    {
      field: 'percentage',
      headerName: t('campaigns.table.sms.header.percentage'),
      flex: 0.083,
      editable: false,
      width: 150,
      renderCell: (params: any) => {
        const not_delivered = parseInt(params.row.not_delivered);
        const triggered = parseInt(params.row.triggered);

        if (params.row.triggered != 0) {
          const result =
            ((triggered - not_delivered) / params.row.triggered) * 100;
          return (
            <Tooltip
              title={
                <NumberMask
                  label={t('campaigns.table.sms.tooltip.percentage')}
                  number={parseInt(result)}
                />
              }
            >
              <NoWrapSpan>
                <NumberMask number={parseInt(result)} />%
              </NoWrapSpan>
            </Tooltip>
          );
        }
        return 0;
      },
    },
    {
      field: 'actions',
      headerName: t('campaigns.table.sms.header.actions'),
      sortable: false,
      editable: false,
      flex: 0.25,
      align: 'center',
      renderCell: params => (
        <PopupState variant="popover" popupId="demo-popup-menu">
          {popupState => (
            <>
              <IconButton variant="contained" {...bindTrigger(popupState)}>
                <ListIcon />
              </IconButton>
              <Menu {...bindMenu(popupState)}>
                <MenuItem
                  onClick={() => {
                    handleOpenDialog(parseInt(params.id.toString()));
                    popupState.close();
                  }}
                  disabled={
                    params.row.status === 'ACTIVE' &&
                    !params.row.bucket &&
                    !params.row.approved
                      ? false
                      : true
                  }
                  style={{
                    opacity:
                      params.row.status === 'ACTIVE' &&
                      !params.row.bucket &&
                      !params.row.approved
                        ? '1'
                        : '0.3',
                  }}
                >
                  {loading ? (
                    <CircularProgress size={20} />
                  ) : (
                    t('campaigns.table.sms.tooltip.actions.trigger')
                  )}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    params.row.status !== 'PAUSED'
                      ? handlePause(params.row.id)
                      : handleResume(params.row.id);
                    popupState.close();
                  }}
                  disabled={
                    params.row.status === 'RUNNING' ||
                    params.row.status === 'PAUSED'
                      ? false
                      : true
                  }
                  style={{
                    opacity:
                      params.row.status === 'RUNNING' ||
                      params.row.status === 'PAUSED'
                        ? '1'
                        : '0.3',
                  }}
                >
                  {params.row.status !== 'PAUSED'
                    ? t('campaigns.table.sms.tooltip.actions.pause')
                    : t('campaigns.table.sms.tooltip.actions.play')}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    history.push(
                      `/sms-campaign-details/${params.row.id.toString()}`
                    );
                    handleOpenDialog(parseInt(params.row.id.toString()));
                    popupState.close();
                  }}
                >
                  {t('campaigns.table.sms.tooltip.actions.details')}
                </MenuItem>
                <MenuItem
                  disabled={
                    params.row.status !== 'FINISHED' &&
                    params.row.status !== 'CANCELLED'
                      ? false
                      : true
                  }
                  style={{
                    opacity:
                      params.row.status !== 'FINISHED' &&
                      params.row.status !== 'CANCELLED'
                        ? '1'
                        : '0.3',
                  }}
                  onClick={() => {
                    handleOpenCancelDialog(parseInt(params.row.id.toString()));
                    popupState.close();
                  }}
                >
                  {t('campaigns.table.sms.tooltip.actions.cancel')}
                </MenuItem>
              </Menu>
            </>
          )}
        </PopupState>
      ),
      width: 200,
    },
  ];

  const handlePause = async campaign_id => {
    setLoading(true);
    try {
      const response = await pauseCampaign(campaign_id);
      if (response.status != 200) {
        enqueueSnackbar(t('campaigns.messages.pause-campaign-error'), {
          variant: 'error',
          autoHideDuration: 1500,
        });
        return;
      }
      setLoading(false);
      handleSearch();
    } catch (err) {
      setLoading(false);
      enqueueSnackbar(t('campaigns.messages.pause-campaign-error'), {
        variant: 'error',
        autoHideDuration: 1500,
      });
    }
  };

  const handleResume = async campaign_id => {
    setLoading(true);
    try {
      const response = await resumeCampaign(campaign_id);
      if (response.status != 200) {
        enqueueSnackbar(t('campaigns.messages.resume-campaign-error'), {
          variant: 'error',
          autoHideDuration: 1500,
        });
        return;
      }
      setLoading(false);
      handleSearch();
    } catch (err) {
      setLoading(false);
      enqueueSnackbar(t('campaigns.messages.resume-campaign-error'), {
        variant: 'error',
        autoHideDuration: 1500,
      });
    }
  };

  const handleOpenDialog = triggerId => {
    setTriggerId(triggerId);
    setOpenConfirmDialog(true);
  };

  const handleTriggerCampaign = async () => {
    try {
      const response = await triggerCampaign(triggerId, campaignType);
      if (response.status != 200) {
        enqueueSnackbar(t('campaigns.messages.trigger-campaign-error'), {
          variant: 'error',
          autoHideDuration: 2500,
        });
        return;
      }
      enqueueSnackbar(t('campaigns.messages.trigger-campaign-success'), {
        variant: 'success',
        autoHideDuration: 2500,
      });
      handleSearch();
    } catch (err) {
      enqueueSnackbar(t('campaigns.messages.trigger-campaign-error'), {
        variant: 'error',
        autoHideDuration: 2500,
      });
    }
  };

  const handleCostCenterChange = event => {
    setFields({ ...fields, cost_center_id: event.target.value });
  };

  const columnsMap = {
    SMS: columns,
    WHATSAPP: whatsappColumns,
    RCS: rcsColumns,
		EMAIL: emailColumns,
  };

  const reportMap = {
    SMS: report,
    WHATSAPP: whatsappReport,
    RCS: rcsReport,
		EMAIL: emailReport,
  };

  return (
    <Container
      topMiddleContent={
        <MiddleContainer
          handleChange={handleChange}
          status={fields.status}
          total={headers.total}
          analyzed={headers.analyzed}
          invalid={headers.invalid}
          on_trigger={headers.on_trigger}
          triggered={headers.triggered}
          delivered={headers.delivered}
          not_delivered={headers.not_delivered}
          handleSearch={() => {
            handleSearch(null);
          }}
          handleCostCenterChange={handleCostCenterChange}
          costCenters={costCenters}
          startDate={fields.start_date}
          handstartDateChange={handstartDateChange}
          endDate={fields.end_date}
          handendDateChange={handendDateChange}
          clearFilters={clearFilters}
          blacklist={headers.blacklist}
          fields={fields}
          campaignType={campaignType}
          handleCampaignTypeChange={handleCampaignTypeChange}
          hasSms={is_main}
          hasWhatsapp={hasWhatsapp}
					hasEmail={hasEmail}
          hasRCS={hasRCS}
        />
      }
    >
      {loading ? (
        <LoadingContainer>
          <CircularProgress size={24} />
        </LoadingContainer>
      ) : (
        <DataGrid
          rowHeight={35}
          rows={reportMap[campaignType]}
          columns={columnsMap[campaignType]}
          pageSize={PAGE_SIZE}
          rowsPerPageOptions={[40]}
          rowCount={pageCount}
          pagination
          paginationMode="server"
          page={currentPage}
          onPageChange={handlePageChange}
          sx={{
            '& .MuiDataGrid-columnHeader': {
              width: 'auto !important',
              minWidth: '0',
            },
          }}
        />
      )}
      <ConfirmDialog
        open={openConfirmDialog}
        title={t('campaigns.messages.trigger-campaign-confirm-title')}
        message={t('campaigns.messages.trigger-campaign-confirm-message')}
        handleCancel={handleCancelDialog}
        handleOk={handleOkDialog}
        handleClose={handleCloseDialog}
      />
      <ConfirmDialog
        open={openCancelDialog}
        title={t('campaigns.messages.trigger-campaign-cancel-title')}
        message={t('campaigns.messages.trigger-campaign-cancel-message')}
        handleCancel={handleCloseCancelDialog}
        handleOk={handleOkCancelDialog}
        handleClose={handleCloseCancelDialog}
      />
    </Container>
  );
}
